// ** --------- ellis for max 2 line ------------ **
.multi-line-2-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ** ------- image avatar grouping ------------ **

.avatar-group {
  display: flex;
  align-items: center;

  .profile-img-p {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #ddd;
    object-fit: cover;
    border: 2px solid white; // To ensure avatars are distinct from one another
    margin-left: -15px; // Adjust as needed for the overlay effect

    &:first-child {
      margin-left: 0;
    }
  }

  .more-avatars {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 2px solid white; // To ensure this is distinct from the avatars
    margin-left: -15px; // Adjust as needed for the overlay effect
  }
}
