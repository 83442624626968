@import "./variables/variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

//styling scrollbar

::-webkit-scrollbar {
    width: 5px;
    margin-left: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// audio element styles
/* For Chrome or Safari */
progress::-webkit-progress-bar {
    background-color: #dcdcdc;
    border-radius: 10px !important;
    height: 8px;
}

progress::-webkit-progress-value {
    background-color: #f58220 !important;
    border-radius: 10px !important;
    height: 8px;
}

/* For Firefox */
progress {
    background-color: #dcdcdc;
    border-radius: 10px !important;
    height: 8px;
}

progress::-moz-progress-bar {
    background-color: #f58220 !important;
    border-radius: 10px !important;
    height: 8px;
}

/* For IE10 */
progress {
    background-color: #dcdcdc;
    border-radius: 10px !important;
    height: 8px;
}

progress {
    background-color: #f58220;
    border-radius: 10px !important;
    height: 8px;
}

// styles for the pills/chips in tables
.statusSuccess {
    padding: 5px 7px !important;
    border-radius: 15px !important;
    border: none !important;
    background: #e7ffef !important;
    color: #12b84b !important;
}
.statusCancel {
    border-radius: 15px !important;
    border: none !important;
    background: #ffe1d8 !important;
    color: #fb4f19 !important;
    padding: 5px 7px !important;
}

// react-data table
// .rdt_TableCell {
//     color: #6e6b7b !important;

//     font-size: 14px;
//     font-weight: 600;
// }

// .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
//     font-size: 12px;
//     font-weight: 400;
// }

// .react-dataTable .dropdown-menu.show {
//     position: fixed !important;
// }

// .react-dataTable .filter .dropdown-menu.show {
//     right: 0 !important;
//     left: auto !important;
// }

//styling components

body {
    font-family: "Montserrat", sans-serif !important;
}

.effects {
    border-left: 3px solid #f58220 !important;
}

.icon {
    width: 20px;
    display: inline;
}

.user-bell {
    margin-right: 10px;
}

.nav-link-style {
    gap: 10px;
    display: flex;
    align-items: center;
}

.nav-link-right-icon {
    border-left: 1px solid #ebe9f1;
    margin-left: 10px;
    padding-left: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
}

.react-select {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 100%;
}

.react-select p {
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: -0px;
}

.css-1s2u09g-control {
    border: none !important;
}

.css-tj5bde-Svg {
    color: $primary !important;
}

.css-tlfecz-indicatorContainer {
    padding-left: 0 !important;
}

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.menu-active {
    box-shadow: none !important;
}

.add-task {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    p {
        font-size: 18px;
        margin-top: 10px !important;
        font-family: "Montserrat", sans-serif;
    }

    .with-nav {
        display: flex !important;
        align-items: flex-end;

        ul {
            padding-right: 40px;
            display: flex;
            align-items: flex-end;
            min-height: 100%;
            margin: 0;

            li {
                cursor: pointer;
                list-style: none;
                margin: 0px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0 10px;
                padding: 0 5px;
                padding-bottom: 7px;
                border-bottom: 3px solid transparent;
            }

            .active {
                color: #f58220;
                border-bottom: 3px solid #f58220;
            }
        }
    }

    button {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 10px;
            font-family: "Montserrat", sans-serif;
        }
    }

    .profile {
        display: flex;
        margin-top: 50px;
        align-items: center;

        img {
            width: 50px;
            border-radius: 100%;
        }

        p {
            font-size: 14px;
            margin-left: 20px;

            span {
                font-weight: 600;
            }
        }

        .AptHistory {
            display: flex;
            align-items: center;
            gap: 0 20px;

            .bl {
                border-left: 2px solid black;
                padding-left: 20px;

                span {
                    margin-left: 10px;
                }
            }
        }
    }
}

.history {
    align-items: start !important;
}

.history-select {
    width: 300px;
    margin-right: 15px;

    .select__control {
        width: 100%;
    }
}

.profile {
    display: flex;
    margin-left: 20px;

    p {
        margin: 0;
    }

    .form-check-inline {
        margin: 0;
    }
}

.profile-row {
    .form-check-inline {
        margin-left: 10px;
    }
}

.rdt_TableHeadRow {
    height: 45px !important;
}

.modal-sticky {
    width: 100%;
    display: flex !important;
    position: fixed !important;
    height: 100%;
    top: 0 !important;
    right: 0 !important;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    .modal-dialog {
        border-radius: 16px !important;
        max-width: 1000px;
        max-height: 90vh;
        overflow: hidden;

        .modal-content {
            width: 100%;
            max-height: 90vh;
            overflow-y: auto;

            .close {
                position: absolute;
                top: 7px;
                right: 10px;
                cursor: pointer;
                z-index: 999;
            }

            .modalbody {
                overflow-x: hidden;

                .editUser {
                    padding: 40px 30px !important;
                    width: 450px;
                }

                .addUser {
                    padding: 40px 30px !important;
                    width: 450px;
                }

                .addCredits {
                    padding: 40px 30px !important;
                    width: 450px;

                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        button {
                            width: 48%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }
                }

                .deleteCallerId {
                    padding: 30px !important;
                    width: 400px;

                    .arrow {
                        color: #6e6b7b;
                        left: 20px !important;
                        top: 15px !important;
                        position: absolute;
                    }

                    .delete-caller {
                        .sc-dIouRR {
                            display: none !important;
                        }
                    }

                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        button {
                            width: 48%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }
                }

                .editProfilePic {
                    width: 400px !important;
                    padding: 30px !important;

                    .editProfilePicImg {
                        min-width: 200px;
                        min-height: 200px;
                        height: 200px;
                        width: 200px;
                        border-radius: 100%;
                        background-color: #5555553f;
                    }

                    .dropDown {
                        padding: 10px;
                        box-shadow: 2px 2px 10px #ddd;
                        border-radius: 10px;
                        background: white;
                        position: absolute;
                        top: 50px;
                        right: -20px;

                        li {
                            list-style: none;
                            margin-top: 3px;
                            padding: 5px 10px;
                            cursor: pointer;
                            border-radius: 5px;
                            transition: 0.3s all ease;
                        }

                        li:hover {
                            background: #f58220;
                            color: white;
                        }
                    }

                    .editIcon {
                        position: absolute;
                        right: 20px;
                        top: 10px;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        background: #f58220;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        .icon {
                            color: white;
                        }
                    }
                }

                .information {
                    padding: 20px 30px !important;
                    width: 700px;

                    h4 {
                        margin-bottom: 30px;
                    }

                    div {
                        .in {
                            display: flex;
                            align-items: center !important;
                            padding: 10px 0;
                            border-top: 2px solid rgba(234, 234, 234, 0.8);

                            p {
                                margin: 0;
                            }

                            h5 {
                                margin: 0;
                                padding-left: 10px;
                            }

                            .child {
                                width: 50%;
                                display: flex;
                                align-items: center !important;
                                overflow: hidden;

                                span {
                                    font-weight: bold;
                                }

                                div {
                                    margin-left: 20px;
                                    width: 100% !important;
                                    display: flex;
                                    align-items: center !important;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .profilePic {
                    width: 400px !important;
                    padding: 30px !important;

                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;

                        button {
                            width: 48%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }
                }

                .commissionAmt {
                    width: 600px !important;
                    padding: 32px !important;

                    .lineSeparate::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        margin-block: auto;
                        right: 40px;
                        width: 5px;
                        height: 20px;
                        background: #d8d6de;
                    }
                    .button {
                        width: 60%;
                        min-height: 37px !important;
                        margin-left: auto;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        button {
                            width: 48%;
                            padding-block: 15px !important;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }

                    .partnerUrl {
                        padding-left: 163px;
                    }

                    .fixedUrl {
                        position: absolute;
                        top: 9.5px;
                        left: 12px;
                    }
                    .inputPercentage:disabled {
                        background: white;
                        cursor: not-allowed;
                    }
                }

                .creditsDefaultAmt {
                    width: 450px !important;
                    padding: 32px !important;

                    .react-select .select__control {
                        border: 1px solid #d8d6de !important;
                    }
                    .button {
                        width: 100%;
                        min-height: 37px !important;
                        margin-left: auto;
                        display: flex;
                        justify-content: space-between;

                        button {
                            width: 48%;
                            padding-block: 15px !important;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }

                    .partnerUrl {
                        padding-left: 163px;
                    }

                    .fixedUrl {
                        position: absolute;
                        top: 9.5px;
                        left: 12px;
                    }
                }

                .sendMail {
                    width: 500px !important;
                    padding: 32px !important;

                    .button {
                        width: 100%;
                        min-height: 37px !important;
                        margin-left: auto;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        button {
                            width: 48%;
                            padding-block: 15px !important;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }

                    .partnerUrl {
                        padding-left: 163px;
                    }

                    .fixedUrl {
                        position: absolute;
                        top: 9.5px;
                        left: 12px;
                    }
                }

                .addPartner {
                    width: 450px !important;
                    padding: 30px !important;

                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        button {
                            width: 48%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }

                    .partnerUrl {
                        padding-left: 163px;
                    }

                    .fixedUrl {
                        position: absolute;
                        top: 9.5px;
                        left: 12px;
                    }
                }

                .BlockCountries {
                    width: 500px !important;
                }

                .viewAdmin {
                    width: 500px !important;
                }

                .manageGroup {
                    padding: 30px 30px !important;
                    width: 650px;

                    .add-task {
                        width: 100%;
                        justify-content: start !important;

                        .cen {
                            width: 100%;
                            display: flex;
                            justify-content: center !important;
                        }

                        p {
                            margin: 0 !important;
                            font-size: 16px;
                            font-weight: 600;
                            margin-right: 15px !important;
                        }
                    }

                    .mn-table {
                        border-style: solid;
                        border-width: 1px;
                        border-color: rgba(0, 0, 0, 0.12);
                        border-top: 0;

                        header {
                            display: none !important;
                        }
                    }
                }

                .caller {
                    width: 400px !important;

                    .button {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 35px;

                        .butt {
                            width: 45%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }
                }

                .sms {
                    padding: 20px 20px !important;
                    width: 500px;

                    .semibold {
                        font-weight: 600;
                    }

                    .color {
                        color: #19bcbe;
                    }

                    .message {
                        margin-top: 20px;
                    }

                    .right-button {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        .button {
                            width: 60%;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 40px;

                            button {
                                width: 48%;
                            }

                            .cancel {
                                background: none !important;
                                color: #333333 !important;
                                border-color: #d8d6de !important;
                            }
                        }
                    }
                }

                .credits-title {
                    margin: 0;
                }

                .accountStatus {
                    .checkbox {
                        width: 90% !important;

                        p {
                            margin-left: 5px;
                            font-size: 14px;
                        }
                    }
                }

                .loginAccount {
                    width: 500px !important;

                    .right-button {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        .button {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 40px;

                            button {
                                width: 48%;
                            }

                            .cancel {
                                background: none !important;
                                color: #333333 !important;
                                border-color: #d8d6de !important;
                            }
                        }
                    }
                }

                .delete {
                    padding: 30px 20px !important;
                    width: 350px;
                    display: flex;
                    flex-direction: column;

                    img {
                        width: 65px;
                        margin: auto;
                    }

                    p {
                        color: #5e5873;
                        font-size: 17px;
                        margin-top: 10px;
                        text-align: center;
                    }

                    span {
                        width: 90.6%;
                        display: block;
                        margin: auto;
                        text-align: center;
                        color: #5e5873;
                        font-size: 14px;
                        margin-bottom: 10px;

                        i {
                            font-style: normal;
                            color: #19bcbe;
                            font-weight: 500;
                        }
                    }

                    .checkbox {
                        width: 80%;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;

                        p {
                            margin: 0;
                            margin-left: 10px;
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                        width: 100%;

                        .butt {
                            width: 45%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }
                }

                .credits-sub-title {
                    margin-top: 10px;
                    text-align: center !important;

                    span {
                        color: #19bcbe;
                        font-weight: 600;
                    }
                }

                .title {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 18px;
                    text-align: center;
                }

                .profile {
                    display: flex;
                    align-items: center;

                    .profile-img {
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                    }

                    .first {
                        color: #28c76f;
                        margin-left: 30px;
                        font-weight: 500;
                    }

                    .last {
                        color: #ff4f4f;
                        margin-left: 20px;
                        font-weight: 500;
                    }
                }

                .add-form {
                    margin-top: 20px;

                    .inputs {
                        margin-top: 16px;

                        .amount {
                            position: relative;

                            .sign {
                                position: absolute;
                                right: 10px;
                                top: 10px;
                                color: #f58220;
                                border-left: 1px solid #d8d6de;
                                padding-left: 10px;
                            }

                            .sign-main {
                                position: absolute;
                                right: 10px;
                                top: 10px;
                                border-left: 1px solid #d8d6de;
                                padding-left: 10px;

                                .check {
                                    color: white;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 100%;
                                    background: green;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .error-message {
                            font-size: 11px;
                            color: red;
                        }

                        .form-label {
                            margin-bottom: 5px;
                            font-size: 14px;
                            color: #82868b;
                        }

                        .input {
                            width: 100%;
                        }

                        .select-error {
                            .select__control {
                                border-color: #ea5455 !important;
                            }
                        }
                    }

                    .password {
                        .input {
                            margin-bottom: 0 !important;
                        }
                    }

                    .switch {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 30px;

                        .switch-left {
                            h6 {
                                margin: 0;
                            }

                            p {
                                margin: 0;
                                font-size: 12px;
                                margin-top: 5px;
                            }

                            a {
                                font-family: "Montserrat", sans-serif;
                                color: #ea5455 !important;
                                font-weight: 600;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 35px;

                        .butt {
                            width: 45%;
                        }

                        .cancel {
                            background: none !important;
                            color: #333333 !important;
                            border-color: #d8d6de !important;
                        }
                    }

                    // .radio {
                    // 	width: 100%;
                    // 	display: flex;
                    // 	justify-content: center;
                    // 	align-items: center;
                    // 	padding-left: 0;
                    // 	margin-top: 10px;

                    // 	li {
                    // 		width: 33%;
                    // 		cursor: pointer;
                    // 		display: flex;
                    // 		justify-content: center;
                    // 		align-items: center;
                    // 		position: relative;

                    // 		.che {
                    // 			position: absolute;
                    // 			width: 100%;
                    // 			height: 100%;
                    // 		}

                    // 		label {
                    // 			padding: 10px 0;
                    // 			display: flex;
                    // 			justify-content: center;
                    // 			align-items: center;
                    // 			width: 100%;
                    // 			color: #B9B9C3;
                    // 			background: white;
                    // 			border: 2px solid #D8D6DE;
                    // 			cursor: pointer;

                    // 			.bl {
                    // 				display: block;

                    // 				div {
                    // 					width: 100%;
                    // 				}
                    // 			}
                    // 		}

                    // 		.bl {
                    // 			border-left: 0;
                    // 		}

                    // 		.cl {
                    // 			border-top-right-radius: 7px;
                    // 			border-bottom-right-radius: 7px;
                    // 		}

                    // 		.ml {
                    // 			border-top-left-radius: 7px;
                    // 			border-bottom-left-radius: 7px;
                    // 		}

                    // 		.checked {
                    // 			background: #19BCBE !important;
                    // 			color: white;
                    // 			border: 2px solid #19BCBE;
                    // 		}
                    // 	}
                    // }

                    .amounts {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0 13px;
                        margin-top: 20px;
                        cursor: pointer;

                        div {
                            padding: 10px 17px;
                            border-radius: 5px;
                            color: #333333;
                            border: 1px solid #d8d6de;
                            background: rgba(245, 130, 32, 0.1);
                            position: relative;

                            .click {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                            }
                        }

                        .activeAmount {
                            background: #f58220;
                            color: white;
                            border-color: #f58220;
                        }
                    }
                }
            }
        }
    }

    .modal-dialog-scrollable {
        height: auto !important;
    }
}

.his {
    display: flex;
    gap: 0 25px;

    li {
        cursor: pointer;
        padding: 0 8px;

        p {
            font-size: 14px;
            margin-left: 7px;
            margin: 0;
            padding-bottom: 6px;
        }
    }

    .active-list {
        color: #f58220;
        border-bottom: 3px solid #f58220;
    }
}

.account {
    width: 100%;
    background: rgba(25, 188, 190, 0.02);
    border: 1px solid rgba(25, 188, 190, 0.5);
    border-radius: 8px;

    .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid rgba(234, 234, 234, 0.8);
        margin: 0 25px;

        div {
            width: 50%;
        }

        .left {
            display: flex;
            align-items: center;

            p {
                margin: 10px 0;
            }

            a {
                margin-left: 20px;
            }
        }

        .right {
            margin: 10px 0;
            display: flex;
            position: relative;

            .enable {
                position: absolute;
                left: 60px;
                top: 4px;
                font-weight: 600;
            }

            .badge {
                padding: 7px 15px !important;
                font-size: 14px;

                .arrow {
                    margin-left: 25px;
                }
            }
        }
    }

    .act {
        border-bottom: 0;
    }
}

.information {
    .left {
        width: 30% !important;
        justify-content: space-between;
        margin-right: 50px;

        .clo {
            font-weight: bold;
        }
    }

    .right {
        width: 70% !important;
    }
}

.react-select {
    padding: 0 !important;

    .select__control {
        padding: 2px 4px;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0.357rem;
        width: 100%;
    }
}

.login {
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    border-bottom: 7px solid #f58220;
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
        position: absolute;
        z-index: 0 !important;
    }

    .forgot {
        height: 450px;
        position: absolute;
        left: 35.5%;
        width: 500px;
    }

    .login-com {
        width: 400px;
        min-height: 100px;
        background: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
        padding: 20px 30px;
        position: absolute;
        z-index: 99 !important;

        img {
            display: block;
            margin: auto;
        }

        p {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            margin-top: 20px;
            color: #5e5873;
        }

        span {
            width: 100%;
            display: block;
            margin-top: 5px;
        }

        .login-form {
            margin-top: 15px;
        }

        .checkbox {
            label {
                margin-left: 5px;
                font-size: 15px;
            }
        }

        .butt {
            width: 100%;
            display: block;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.check {
    color: white;
    width: 25px;
    max-width: 25px;
    margin-left: 10px;
    height: 25px;
    border-radius: 100%;
    background: green;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-close {
    background: #F2A608 !important;
    color: white;
    height: 25px ;
    width: 25px;
    padding: 5px;
    padding-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-img-p {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 10px;
}

.profile-img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.partner-table {
    .profile {
        margin: 20px 0;
        display: flex;
        align-items: center;

        p {
            margin-left: 20px;
        }
    }
}

.cropper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.349);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    .crop-main {
        width: 380px;
        background: white;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 40px 30px !important;
        overflow: hidden !important;
        border-radius: 20px !important;
        position: relative;

        .close {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        h3 {
            margin: 0 !important;
            margin-bottom: 20px !important;
        }

        .crop {
            width: 300px;
            overflow: hidden !important;

            svg {
                display: none !important;
            }
        }

        .button {
            width: 94%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            button {
                width: 48%;
            }

            .cancel {
                background: none !important;
                color: #333333 !important;
                border-color: #d8d6de !important;
            }
        }
    }
}

.success-modal {
    .icon {
        text-align: center;
        height: 64px;
        width: 64px;
    }

    .login-com {
        h4 {
            margin-top: 5px;
        }

        p {
            font-weight: 400;
        }
    }

    .background {
        height: 400px !important;
        width: 500px !important;
    }
}

.otpInput {
    margin: 0 3px;

    input {
        width: 50px !important;
        height: 50px !important;
        border-radius: 5px;
        outline: none;
        border: 2px solid #ddd;
        font-size: 22px;
    }
}

.otp {
    .background {
        height: 450px !important;
        width: 500px !important;
    }
}

.mobileNumber {
    width: 100% !important;
    z-index: 9999;

    input {
        width: 100% !important;
        height: 39px !important;
        border: 1px solid #d8d6de;
        border-radius: 0.357rem !important;
        transition: 0.3s all ease;
    }

    input:focus {
        border: 1px solid #f58220;
    }

    .flag-dropdown {
        border: 1px solid #d8d6de !important;
        transition: 0.3s all ease;
    }

    .onFouce {
        border: 1px solid #f58220 !important;
    }
}

.phone-error {
    .flag-dropdown {
        border: 1px solid red !important;
    }

    input {
        border: 1px solid red !important;
    }
}

.api-loader {
    height: 0 !important;
}

.spainner {
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.212) !important;
    flex-direction: column;
}

.table-spainner {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skeleton-profile-pic {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100% !important;
}

.skeleton-profile {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100% !important;
}

.skeleton-table-tittle {
    margin-left: 10px;
}

.compose-form div .days {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px 10px;

    div {
        border: 1px solid #d8d6de;
        border-radius: 38px;
        padding: 4px 16px;
        color: rgba(110, 107, 123, 0.75);
        cursor: pointer;
    }

    .active {
        background: rgba(255, 159, 67, 0.12);
        color: #f58220;
        border-color: #f58220;
    }
}

.filter .dropdown-menu {
    width: 400px !important;
    left: -170px !important;

    .filter-dropdown {
        padding: 20px;
        z-index: 99999;

        .search {
            position: absolute;
            right: 20px;
            top: 50%;
        }

        .datePopup {
            position: absolute;
            top: 130%;
            background: white;
            box-shadow: 2px 2px 10px #ddd;
        }

        .dates {
            position: absolute;
            left: 10px;
            top: 52%;
        }

        .days {
            display: flex;
            margin-top: 20px;
            flex-wrap: wrap;
            gap: 10px 10px;

            div {
                border: 1px solid #d8d6de;
                border-radius: 38px;
                padding: 4px 16px;
                color: rgba(110, 107, 123, 0.75);
                cursor: pointer;
            }

            .active {
                background: rgba(255, 159, 67, 0.12);
                color: #f58220;
                border-color: #f58220;
            }
        }

        .button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            button {
                width: 48%;
            }

            .cancel {
                background: none !important;
                color: #333333 !important;
                border-color: #d8d6de !important;
            }
        }
    }
}

.rti--tag {
    padding: 4px 13px !important;
    border-radius: 30px !important;
    background: #fff3e8 !important;

    span {
        text-transform: capitalize;
        color: #333333;
    }
}

.error-message {
    font-size: 11px;
    color: red;
}

.date-error {
    border: 1px solid red !important;
}

.buyWithCard {
    .addNewCard {
        border-radius: 5px;
        border: 1px solid #e4e7ec;
        background: white;
        transition: 0.3s all ease;
    }

    .addNewCard:hover {
        border: 1px solid transparent;
        background: #e4e7ec;
    }
}

.two-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inputs {
        width: 48%;
    }
}

.flatpickr-monthSelect-months {
    flex-wrap: wrap !important;
    display: flex;
    padding: 10px;

    .flatpickr-monthSelect-month {
        width: 33.3%;
        padding: 10px 0;
        cursor: pointer !important;
        transition: 0.3s all ease;
        border-radius: 5px;
    }

    .flatpickr-monthSelect-month:hover {
        background: #ddd;
    }
}

.cardNu {
    .sc-jIZahH {
        width: 100%;
    }

    #field-wrapper {
        border: 1px solid #d8d6de;
        width: 106% !important;
        border-radius: 5px;
        padding: 11px;
    }

    #card-expiry {
        display: none !important;
    }

    #cvc {
        display: none !important;
    }

    .sc-ciZhAO {
        margin: 0;
    }
}

.cards {
    .react-loading-skeleton {
        width: 100% !important;
        margin: 0;
        margin-top: 10px;
    }

    margin-bottom: 30px;

    .singleCard {
        padding: 6px 15px;
        border: 1px solid #d8d6de;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .card-logo {
            border: 0.778215px solid #f2f4f7;
            border-radius: 3px;
            padding: 7px 5px;

            img {
                max-width: 30px;
            }
        }

        div {
            display: flex;
            align-items: center;
        }

        p {
            padding-left: 10px;
            font-size: 15px;
            margin-top: 15px;
        }
    }
}

.amount {
    position: relative;

    .sign {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #f58220;
        border-left: 1px solid #d8d6de;
        padding-left: 10px;
    }
}

.cuvmqH {
    width: 100% !important;
}

.user-list {
    border: 1px solid #e9ecef;
    border-radius: 8px;
    width: 100%;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    div {
        margin-left: 15px;
        p {
            margin: 0;
        }
        h5 {
            margin: 0;
        }
    }
    img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
    }
}
.active-user-list {
    background: #f9fafa !important;
    border: 1px solid #f58220 !important;
}
.profile-picture {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100% !important;
}
.flagSelect {
    border-radius: 10px !important;
    .ReactFlagsSelect-module_selectOption__3pcgW {
        list-style: none !important;
    }
    .ReactFlagsSelect-module_filterBox__3m8EU {
        input {
            border-radius: 5px;
            border: 1px solid #d8d6de;
        }
    }
    .ReactFlagsSelect-module_selectOptions__3LNBJ {
        box-shadow: -2px 2px 43px #ddd;
        border: none;
        padding-left: 0 !important;
        margin-top: 5px;
        li {
            padding: 10px 20px !important;
            margin-left: 5px !important;
        }
    }
    .ReactFlagsSelect-module_selectBtn__19wW7 {
        border-radius: 5px !important;
        border: 1px solid #d8d6de;
    }
}

.button-groups {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    border: 1px solid #f58220 !important;
    border-radius: 5px;
    overflow: hidden;
    width: 45%;
    button {
        width: 33%;
    }
    .non-active {
        color: #5e5873 !important;
        background: none !important;
        border: 0 !important;
    }
}

.small-child-button {
    width: 94%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
        width: 200px;
    }

    .cancel {
        background: none !important;
        color: #333333 !important;
        border-color: #d8d6de !important;
    }
}

.asSelect {
    margin: 20px 0 !important;
    button {
        width: 50% !important;
        border-radius: 0 !important;
    }
}
.popupItem:hover {
    background: #f583203d;
}

.buttons {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
        width: 48%;
    }

    .cancel {
        background: none !important;
        color: #333333 !important;
        border-color: #d8d6de !important;
    }
}
.select__menu {
    z-index: 999 !important;
}
.dateWidth {
    width: 100% !important
}
.addUser {
    .select__control {
        height: 10px !important;
    }
}
.hel-pri {
    background-color: #f58220 !important;
    border: 0 !important;
}